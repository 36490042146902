module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Agicap","short_name":"Agicap","start_url":"/","background_color":"#316DF0","theme_color":"#316DF0","display":"minimal-ui","icon":"static/favicon.svg","icons":[],"include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"01603bed215b1b5948c515b254729871"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"maintainCase":false,"removeAccents":false,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-Frame-Options: SAMEORIGIN"]},"mergeCachingHeaders":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"wjGo3dC9ONwBFg1zgnEGT5VhOcFZdu0I","devKey":"wjGo3dC9ONwBFg1zgnEGT5VhOcFZdu0I","trackPage":false,"trackPageDelay":50,"delayLoad":false,"delayLoadTime":100,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
