import React from "react"
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form"
import "./src/styles/global.scss"

let isFirstRender = true
export const onRouteUpdate = ({ location, prevLocation }) => {
  // clear the page data
  // this is done so that the fresh data is used for the tracking event in usePageTracking hook
  if (isFirstRender) {
    isFirstRender = false
  } else {
    window.__agicap_page_data = null
  }

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', appHeight)
  appHeight()
}

export const wrapRootElement = ({ element }) => (
  <HubspotProvider>{element}</HubspotProvider>
)
